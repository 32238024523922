import React from "react"
// import PropTypes from "prop-types"
import Masonry from 'react-masonry-component'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import { Capitalize } from "../components/utils"

// Components
import { Link, graphql } from "gatsby"

const SkillPage = ({ pageContext, data }) => {
   const { skill } = pageContext
   // const { edges, totalCount } = data.allMdx
   
   // const skillHeader = `${totalCount} project${
   //    totalCount === 1 ? "" : "s"
   // } tagged with "${skill}"`

   return (
      <Layout>
         <article className="sheet">
            <h1 className="sheet__title">{Capitalize(skill)} Projects</h1>
            {/* <div className="sheet__inner">
               <h1 className="sheet__title">{Capitalize(skill)} Projects</h1>
               <div className="sheet__body">
               <h3>{skillHeader}</h3>
               <ul>
               {edges.map(({ node }) => {
                  const { slug } = node.fields
                  const { title } = node.frontmatter
                  return (
                     <li key={slug}>
                     <Link to={`/${slug}`}>{title}</Link>
                     </li>
                  )
               })}
               </ul>
               <Link to="/skills">All skills</Link>
               </div>
            </div> */}
         </article>
         <Masonry className="showcase">
         {data.allMdx.edges.map(({ node }) => (
            <div key={node.id} className="showcase__item">
               <figure className="card">
                  <Link to={`/${node.fields.slug}`} className="card__image">
                  <Img fluid={node.fields.thumbnail.childImageSharp.fluid} />
                  </Link>
                  <figcaption className="card__caption">
                     <h6 className="card__title">
                        <Link to={`/${node.fields.slug}`}>{node.frontmatter.title}</Link>
                     </h6>
                     <div className="card__description">
                        <p>{node.frontmatter.summary}</p>
                     </div>
                  </figcaption>
               </figure>
            </div>
         ))}
         </Masonry>
      </Layout>
   )
}

// SkillPage.propTypes = {
//    pageContext: PropTypes.shape({
//    skill: PropTypes.string.isRequired,
// }),
//    data: PropTypes.shape({
//       allMdx: PropTypes.shape({
//          totalCount: PropTypes.number.isRequired,
//          edges: PropTypes.arrayOf(
//          PropTypes.shape({
//             node: PropTypes.shape({
//                frontmatter: PropTypes.shape({
//                title: PropTypes.string.isRequired,
//                }),
//                fields: PropTypes.shape({
//                slug: PropTypes.string.isRequired,
//                }),
//             }),
//          }).isRequired
//          ),
//       }),
//    }),
// }

export default SkillPage

export const pageQuery = graphql`
   query($skill: String) {
      allMdx(
         filter: {frontmatter: {skills: {in: [$skill]}, published: {eq: true}, type: {eq: "project"}}},
         sort: {fields: [frontmatter___date], order: DESC}) {
         totalCount
         edges {
            node {
               frontmatter {
                  title
                  summary
               }
               fields {
                  slug
                  thumbnail {
                     childImageSharp {
                        fluid(maxWidth: 450) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
         }
      }
   }
`